import { eventBus, betslipUtility, ticketCheck } from '../utility';
import store from '../store';
import { isEmpty } from 'lodash';

const successTicketHandler = function (data) {
  // Send message over GGateway event 'Tickets.Update' to update state of ticket
  const ticketData = {
    action: store.getters.ticketAction, // available values: Add, Cancel, Payout
    ticket: betslipUtility.prepareSevenTicketData(data.productTicket || data),
  };
  // #8693jgrtn INFO IN TASK
  if (data.status.value !== 'INVALID') {
    eventBus.$emit('ticketUpdate', ticketData);
    eventBus.$emit('printTemplate', ticketData);
  }
};

const errorTicketHandler = function (error, ticket = {}) {
  const errorMessage = error.response?.data?.message || error.response?.data?.error || error.message;
  if (!isEmpty(ticket)) {
    // TODO maybe there is a better way to do this
    // Sending ticket with rejected status and localStatus to remove the wait for ticket terminal app notification
    // eslint-disable-next-line no-param-reassign
    ticket.status = 'REJECTED';
    // eslint-disable-next-line no-param-reassign
    ticket.localStatus = 'REJECTED';
    const ticketData = {
      action: ticket.action, // available values: Add, Cancel, Payout
      ticket,
    };
    // Handling axios request creation errors
    // If error isn't from backend don't send ticket update
    // Covers case where ticketUpdate and ticketCheck responses are missing on ticket payin action
    if (error.response && error.response.status === 404) {
      ticketCheck.stopTicketChecker(ticket);
      eventBus.$emit('ticketUpdate', ticketData);
      return;
    }
  }
  store.dispatch('sendGGMessage', { message: errorMessage });
};

export { successTicketHandler, errorTicketHandler };
