/*
Use this file to parse and set your configurations.
You can use process.env to get enviroment variables set in env files.
*/
import apiRoutes from './routes';

const appEnvironment = process.env.NODE_ENV;
const routes = apiRoutes[appEnvironment];

const getApiRoute = (routeName, config) => {
  const env = config && config.environment ? config.environment : appEnvironment;
  if (routes) {
    return apiRoutes[env][routeName];
  }
  return routes[routeName];
};
export { appEnvironment, routes, getApiRoute };
