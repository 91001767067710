import axios from 'axios';
import store from '../store';
import { getApiRoute } from '../../config';

/*
Change baseURL to suit your project needs.
*/
const http = axios.create({
  timeout: 5000,
});

let token, tpToken;

http.interceptors.response.use(
  (response) => {
    if (response.config.url === 'https://menhir.gb.nsoftcdn.com/users/b2b/authenticate.json') {
      token = response.headers['access-token'];
      tpToken = response.headers['x-nsft-seven-tp-token'];
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

http.interceptors.request.use((config) => {
  // Third party login for web terminal integration
  // eslint-disable-next-line
  if (token) config.headers['Authorization'] = `Bearer ${token}`;
  // eslint-disable-next-line
  if (tpToken) config.headers['Seven-Tp-Token'] = tpToken;
  // standard login for ticket check requests
  const aggregatorURL = getApiRoute('baseURL');
  if (config.url.includes(aggregatorURL)) {
    const { uuid, password } = store.getters.account;
    const { deviceId } = store.getters;
    const token = store.getters.terminalUser?.auth?.token;
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers['Authorization'] = `Bearer ${token}`;
    } else if (uuid && password) {
      const basicAuthToken = btoa(`${uuid}:${password}`);
      // eslint-disable-next-line no-param-reassign
      config.headers['Authorization'] = `Basic ${basicAuthToken}`;
      // eslint-disable-next-line no-param-reassign
      config.headers['X-Nsft-Ngs-deviceId'] = deviceId;
    }
  }
  return config;
});
export default http;
