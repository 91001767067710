import * as Sentry from '@sentry/vue';

const start = function (app) {
  Sentry.init({
    app,
    environment: process.env.NODE_ENV,
    dsn: 'https://c13ceae543314295a15bccc83c6532df@o73276.ingest.us.sentry.io/4506902432841728',
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
export default { start };
