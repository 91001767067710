export default {
  development: {
    baseURL: 'https://games-aggregator.staging.de-2.nsoft.cloud',
    resultsURL: 'https://games-crash-cash-history.staging.de-2.nsoft.cloud',
    historyURL: 'https://games-read-api.staging.de-2.nsoft.cloud',
    socketConfigURL: 'https://games-crash-cash-registry.staging.de-2.nsoft.cloud',
    sevenUrl: 'https://menhir.staging.gb.nsoftcdn.com',
    sevenAuthUrl: 'https://menhir.gb.nsoftcdn.com',
    gameResults: '/results/game/',
    ticketPayin: '/web/tickets/add.json',
    ticketPayout: '/tickets',
    ticketCancel: '/tickets',
    ticketHistory: '/tickets',
    ticketBarcodeCheck: '/tickets/barcode/',
    ticketRequestIdCheck: '/tickets/',
    globalConfig: '/subscriptions/',
    auth: '/users/b2b/authenticate.json',
  },
  staging: {
    baseURL: 'https://games-aggregator.staging.de-2.nsoft.cloud',
    resultsURL: 'https://games-crash-cash-history.staging.de-2.nsoft.cloud',
    historyURL: 'https://games-read-api.staging.de-2.nsoft.cloud',
    socketConfigURL: 'https://games-crash-cash-registry.staging.de-2.nsoft.cloud',
    sevenUrl: 'https://menhir.staging.gb.nsoftcdn.com',
    sevenAuthUrl: 'https://menhir.gb.nsoftcdn.com',
    gameResults: '/results/game/',
    ticketPayin: '/web/tickets/add.json',
    ticketPayout: '/seven/tickets/actions/payout',
    ticketCancel: '/seven/tickets/actions/cancel',
    ticketHistory: '/tickets',
    ticketBarcodeCheck: '/seven/tickets/barcode',
    ticketRequestIdCheck: '/seven/tickets',
    globalConfig: '/subscriptions/',
    auth: '/users/b2b/authenticate.json',
  },
  production: {
    baseURL: 'https://games-aggregator.de-2.nsoft.cloud',
    resultsURL: 'https://games-crash-cash-history.de-2.nsoft.cloud',
    historyURL: 'https://games-read-api.de-2.nsoft.cloud',
    socketConfigURL: 'https://games-crash-cash-registry.de-2.nsoft.cloud',
    gameResults: '/results/game/',
    ticketPayin: '/seven/tickets/',
    ticketPayout: '/seven/tickets/actions/payout',
    ticketCancel: '/seven/tickets/actions/cancel',
    ticketHistory: '/tickets',
    ticketBarcodeCheck: '/seven/tickets/barcode',
    ticketRequestIdCheck: '/seven/tickets',
    globalConfig: '/subscriptions/',
  },
};
