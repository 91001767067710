import http from './http';
import store from '../store';
import { getApiRoute } from '../../config';

export default {
  async getTicketHistory() {
    const { config, terminalUser, gameName, company, deviceId } = store.getters;
    const { count, page, timeFrom, timeTo } = store.getters.ticketHistoryFetchData;

    const dateFrom = timeFrom.replace(' ', 'T') + 'Z';
    const dateTo = timeTo.replace(' ', 'T') + 'Z';

    const params = `?size=${count}&page=${page - 1}&dateFrom=${encodeURIComponent(
      dateFrom,
    )}&dateTo=${encodeURIComponent(dateTo)}&devices=${deviceId}&tenants=${company.uuid}&products=${gameName}`;
    const results = await http.get(
      `${getApiRoute('historyURL', config)}${getApiRoute('ticketHistory', config)}${params}`,
      {
        headers: {
          Authorization: `Bearer ${terminalUser.auth.token}`,
        },
      },
    );
    return results.data;
  },
};
