import { createRouter, createWebHistory } from 'vue-router';

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Terminal',
      component: () => import(/* webpackChunkName: "Terminal" */ '@/pages/Terminal'),
    },
    {
      path: '/results',
      name: 'Results',
      component: () => import(/* webpackChunkName: "Results" */ '@/pages/Results'),
    },
    {
      path: '/ticket-details',
      name: 'TicketDetails',
      component: () => import(/* webpackChunkName: "TicketDetails" */ '@/pages/TicketDetails'),
    },
    {
      path: '/tickets-history',
      name: 'TicketHistory',
      component: () => import(/* webpackChunkName: "TicketHistory" */ '@/pages/TicketHistory'),
    },
  ],
});
