import http from './http';
import store from '../store';
import { getApiRoute } from '../../config';

export default {
  async sevenAuth(gameId, token, tenantId) {
    const { config } = store.getters;
    // TEMP
    // LOB ACC EXAMPLE
    //eslint-disable-next-line
    gameId = 'b2e277c6-d90f-4b54-9115-95359c2f72d5';
    //eslint-disable-next-line
    // token = '21f3a532-dd1f-4460-b064-aff5e66215c5';
    //eslint-disable-next-line
    tenantId = 'e6902f23-adb0-4faa-901f-0ea40864e5ab';
    const loginData = await http.post(
      `${getApiRoute('sevenAuthUrl', config)}${getApiRoute('auth', config)}`,
      {
        authStrategy: 'token',
        id: gameId,
        token: token,
      },
      {
        headers: {
          'Http-X-Seven-Club-Uuid': `${tenantId}`,
        },
      },
    );
    return loginData.data;
  },
};
