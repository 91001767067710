import http from '../api/http';
import { getApiRoute } from '../../config';

const getSlaveId = (pathname) => {
  let result;

  switch (pathname) {
    case '/results':
      result = 'PluginCrashCashResults';
      break;
    case '/tickets-history':
      result = 'PluginCrashCashTicketHistory';
      break;
    default:
      result = 'CrashCash';
      break;
  }
  return result;
};
// Unused, keep it till final solution is made, perhaps it can be used later.
const isSubscriptionActive = async (productId) => {
  if (!productId) return;

  try {
    const response = await http.get(`${getApiRoute('socketConfigURL')}${getApiRoute('globalConfig')}${productId}`);
    return response.data.isActive;
  } catch (error) {
    return false;
  }
};

export default { getSlaveId, isSubscriptionActive };
